
.NSDJS .column {
	float: left;
	position: relative;
	/*border-style: solid;*/
	/*border-width: 1px;*/
	height: 100%;	
	/* overflow: scroll; */
	overflow: visible;
}

.NSDJS .columnInner  {	
	height: 100%;
	position: relative;
	/* background: repeating-linear-gradient(
		to right,
		white,
		white 5px,
		#fcfcfc 5px,
		#fcfcfc 10px
	  ); */
}

.NSDJS .columnInnerMiddle, .columnInnerBottom, .rowInnerMiddle, .rowInnerBottom  {	
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;	
    transform: translate(-50%, -50%);
	
}

.NSDJS .columnInnerBottom, .rowInnerBottom {
    top: 100%;
    left: 0; 
    transform: translate(0%, -100%);
}

.NSDJS .rowInner, .rowInnerMiddle, .rowInnerBottom{
	width: 100%;
	display: flex;
	position: relative;
	/* background: linear-gradient(
		to bottom,
		white 5px,
		#fcfcfc 5px
	);
	background-size: 100% 10px; */
}

.NSDJS .rowMenu {
	position: absolute;
	right: 50%;
	margin-right: -65px;
	bottom: -27px;
	cursor: pointer;
	z-index: 10;
}

.NSDJS .columnMenu {
	position: absolute;
	right: -29px;
	bottom: 50%;
	top: 50%;
	margin-top: -79px;
	cursor: pointer;
	z-index: 10;
    line-height: 10px;
}

.NSDJS .elementMenu {
    position: absolute;
    z-index: 99;
    height: 26px;
    margin-top: 0px;
}

.NSDJS .left {
	float: left;
}

.NSDJS .right {
	float: right;
}

.NSDJS .center {
    float: right;
    width: fit-content;
    position: relative;
    left: -50%;
    border: 3px solid green;
}
  
/* Clear floats after the columns */
.NSDJS .row{
	display:flex;
	position: relative;
/*	border-style: solid;
	border-width: 1px;
*/}
.NSDJS .row:after {
	content: "";
	display: table;
	clear: both;
}

.NSDJS .sticky {
  position: fixed;
  top: 0;
  right:0;
  left: 0;
  /* padding-left: 80px; */
  z-index: 1000;
}

/* .NSDJS .sticky + .content {
  padding-top: 200px;
} */


.NSDJS .middle {
/*  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, 0%);
*/}

.NSDJS .clearfix:before,
.NSDJS .clearfix:after {
  display: table;
  content: "";
}
.NSDJS .clearfix:after {
  clear: both;
}

/* /// Overlay - Grid /// */
.NSDJS .overlay{
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  /*background-color: rgba(0,0,0,0.5); */
}
/* /// Overlay - Grid /// */

.NSDJS .elementMainDiv {
	line-height: 40px;
	height: 40px;
	/* padding: 6px 10px; */
	box-sizing : border-box; 
	margin: 0px;
	padding: 0px;
}

.NSDJS .textfield {
	margin: 0px;
	padding-top: 8px;
	box-sizing : border-box; 
	background-color: #fbfbfb;
}

.NSDJS .textfield label {
	font-size: 1em;
    padding: 0px 0px 0px 4px;
    line-height: 22px;
}

.NSDJS .button{
	height: 32px;
	/* font-size: 1.2em; */
	line-height: 1.2em;
}

.NSDJS .iconButton{
	height: 32px;
	padding: 4px 10px;
	color:black;
	border:none;
	cursor: pointer;
}
.NSDJS .iconButton:hover{
	background-color:#2196F3;
	color:white;
}

.NSDJS input.textfield {
	font-size: 1em;
	padding: 6px 0px 0px 4px;
    border-bottom: 0.5px solid rgba(0,0,0,.26);
}

.NSDJS .textareaDiv {
	/* padding: 6px 6px 1px 6px; */
	padding: 0;
	margin:0px;
}

.NSDJS .textarea {
	font-size: 1em;
	resize:none;
	background-color: #fbfbfb;
	/* padding:5px; */
}

.NSDJS .njs-label{
	line-height: 40px;
	height: 40px;
	padding: 0;
	font-size: 1em;
	box-sizing : border-box; 
    color:black;
	/* background-color: white; */
}

.NSDJS .checkbox{
	line-height: 40px;
	height: 40px;
	padding: 0px;
	/* font-size: 1.2em; */
	box-sizing : border-box; 
}

.NSDJS .select{
	background: none;
    border: none;
}

/* /// Button /// */
.NSDJS a.button3{
	display:inline-block;
	padding:0.3em 1.2em;
	/*margin:0 0.3em 0.3em 0;*/
	border-radius:2em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	background-color:#4eb5f1;
	text-align:center;
	transition: all 0.2s;
}
.NSDJS a.button3:hover{
	background-color:#4095c6;
}
/* /// Button /// */


.tooltip {
	position: relative;
	border-bottom: 1px dotted black;
}
.tooltip .tooltiptext {
visibility: hidden;
background-color: black;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 20px;
margin: 20px 0 0 0;
position: absolute;
z-index: 1;
}
.tooltip:hover .tooltiptext {
visibility: visible;
}


/* Resizable */
  .resizers.resizer{
	width: 8px;
	height: 8px;
	background: white;
	border: 1px solid #4286f4;
    box-sizing: border-box;
	position: absolute;
	z-index: 10;
  }
  .resizers.resizer.bottom-middle {
	right: 50%;
	margin-right: -5px;
	bottom: -4.5px;
	cursor: ns-resize;
	opacity: 1;
  }
  .resizers.resizer.right-middle {
	right: -4.5px;
	bottom: 50%;
	top: 50%;
    border:1px solid #f4429c;
	border-radius: 50%; /*magic to turn square into circle*/
	margin-top: -5px;
	cursor: ew-resize;
	opacity: 1;
  }
  /* .resizers .resizer.top-left {
	left: -5px;
	top: -5px;
	cursor: nwse-resize;
  }
  .resizers .resizer.top-right {
	right: -5px;
	top: -5px;
	cursor: nesw-resize;
  }
  .resizers .resizer.bottom-left {
	left: -5px;
	bottom: -5px;
	cursor: nesw-resize;
  }
  .resizers .resizer.bottom-right {
	right: -5px;
	bottom: -5px;
	cursor: nwse-resize;
  } */
  /* Resizable */


/* dim background for modal dialog */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* dim the background */
    z-index: 1000;
    /*display: none;*/ /* hide by default */
  }
  
/* The Modal (background) */
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1000; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Modal Content */
.modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 400px;
    top: 30%;
    bottom: 70%;
    position: relative;}

/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}
.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}


.njs-button {
    align-items: center;
    appearance: button;
    background-color: #0276FF;
    border-radius: 8px;
    border-style: none;
    box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "RM Neue",sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 10px 4px;
    text-align: center;
    text-transform: none;
    transition: color .13s ease-in-out,background .13s ease-in-out,opacity .13s ease-in-out,box-shadow .13s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .njs-button:active {
    background-color: #006AE8;
  }
  
  .njs-button:hover {
    background-color: #1C84FF;
  }


  /* ICONS */

  .njs-undo-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 14px;
    height: 14px;
    border: 2px solid;
    border-left-color: transparent;
    border-radius: 100px
}
.njs-undo-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-left: 2px solid;
    top: -3px;
    left: -1px;
    transform: rotate(-68deg)
}

.njs-redo-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 14px;
    height: 14px;
    border: 2px solid;
    border-right-color: transparent;
    border-radius: 100px
}
.njs-redo-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    top: -3px;
    right: -1px;
    transform: rotate(68deg)
}

.njs-erase-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 18px
}
.njs-erase-ico::after,
.njs-erase-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute
}
.njs-erase-ico::before {
    width: 6px;
    height: 14px;
    border-bottom:
        4px solid transparent;
    border-radius: 1px;
    box-shadow:
        0 0 0 2px,
        inset 0 -2px 0 0;
    left: 7px;
    top: 2px;
    transform: rotate(45deg)
}
.njs-erase-ico::after {
    background: currentColor;
    width: 22px;
    height: 2px;
    bottom: 0;
    border-radius: 20px
}


.njs-image-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 20px;
    height: 16px;
    overflow: hidden;
    box-shadow: 0 0 0 2px;
    border-radius: 2px
}
.njs-image-ico::after,
.njs-image-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border: 2px solid
}
.njs-image-ico::after {
    transform: rotate(45deg);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    top: 9px;
    left: 6px
}
.njs-image-ico::before {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 2px;
    left: 2px
}


.njs-add-ico {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 22px;
	height: 22px;
	border: 2px solid;
	transform: scale(var(--ggs,1));
	border-radius: 22px
 }
 .njs-add-ico::after,
 .njs-add-ico::before {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 10px;
	height: 2px;
	background: currentColor;
	border-radius: 5px;
	top: 8px;
	left: 4px
 }
 .njs-add-ico::after {
	width: 2px;
	height: 10px;
	top: 4px;
	left: 8px
 }
 .njs-add-ico:hover {
    color: white;
	background-color:#2196F3;
}

.njs-add-r-ico {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 22px;
	height: 22px;
	border: 2px solid;
	transform: scale(var(--ggs,1));
	border-radius: 4px
}   
.njs-add-r-ico::after,
.njs-add-r-ico::before {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 10px;
	height: 2px;
	background: currentColor;
	border-radius: 5px;
	top: 8px;
	left: 4px
}   
.njs-add-r-ico::after {
	width: 2px;
	height: 10px;
	top: 4px;
	left: 8px
} 


.njs-text-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 9px;
    height: 2px;
    background: currentColor;
    transform: scale(var(--ggs,1));
    border-radius: 3px;
    box-shadow:
        -2px 8px 0 0,
        2px 8px 0 0
}
.njs-text-ico.top {
	top:10px;
}
.njs-text-ico::after,
.njs-text-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 2px;
    height: 14px;
    background: currentColor;
    top: -8px
}
.njs-text-ico::before {
    transform: rotate(25deg);
    left: 1px
}
.njs-text-ico::after {
    transform: rotate(-25deg);
    right: 1px
}

.njs-number-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 18px;
    height: 18px
 }
 .njs-number-ico::before {
    box-shadow: -2px 10px 0, 2px 10px 0;
 }
 .njs-number-ico::after,
 .njs-number-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    top: 8px;
    left: 4px
 }
 .njs-number-ico::after {
    width: 2px;
    height: 10px;
    top: 4px;
    left: 8px
 }

 .njs-textfield-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 26px;
    height: 10px;
    border: 2px solid;
    border-radius: 2px
}
.njs-textfield-ico.top {
	top:10px;
}
.njs-textfield-ico::after,
.njs-textfield-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 2px;
    border-radius: 10px;
    background: currentColor;
    top: 2px;
    right: 2px
}
.njs-textfield-ico::before {
    right: 6px
}

.njs-textarea-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 20px;
    height: 14px;
    border: 2px solid transparent
}
.njs-textarea-ico.top {
	top:8px;
}
.njs-textarea-ico::after,
.njs-textarea-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    height: 2px;
    border-radius: 3px;
    background: currentColor
}
.njs-textarea-ico::before {
    box-shadow: 0 4px 0 0;
    top: 0;
    width: 16px
}
.njs-textarea-ico::after {
    width: 10px;
    bottom: 0
}

.njs-checkbox-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 22px;
    height: 22px;
    border: 2px solid;
    transform: scale(var(--ggs,1));
    border-radius: 4px
}
.njs-checkbox-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 3px;
    top: -1px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg)
}


.njs-select-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 20px;
    border: 2px solid;
    border-radius: 3px
}
.njs-select-ico::after,
.njs-select-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 2px;
    background: currentColor;
    top: 3px;
    left: 3px;
    box-shadow:
        0 4px 0,
        0 8px 0
}
.njs-select-ico::after {
    border-radius: 3px;
    width: 8px;
    left: 7px
}


.njs-image-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 20px;
    height: 16px;
    overflow: hidden;
    box-shadow: 0 0 0 2px;
    border-radius: 2px
}
.njs-image-ico.top {
	top:4px;
}
.njs-image-ico::after,
.njs-image-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border: 2px solid
}
.njs-image-ico::after {
    transform: rotate(45deg);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    top: 9px;
    left: 6px
}
.njs-image-ico::before {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    top: 2px;
    left: 2px
}

.njs-table-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    border: 2px solid;
    border-radius: 3px;
    width: 20px;
    height: 14px
}
.njs-table-ico.top {
	top:6px;
}
.njs-table-ico::after,
.njs-table-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    background: currentColor
}
.njs-table-ico::after {
    width: 2px;
    height: 8px;
    bottom: -2px;
    left: 4px
}
.njs-table-ico::before {
    width: 20px;
    height: 2px;
    left: -2px;
    top: 4px
}

.njs-assign-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 6px;
    height: 6px;
    background: currentColor;
    transform: scale(var(--ggs,1));
    border-radius: 100px
}
.njs-assign-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    box-shadow:
        -5px -5px 0 -3px,
        5px 5px 0 -3px,
        5px -5px 0 -3px,
        -5px 5px 0 -3px;
    left: -3px;
    top: -3px
}
.njs-assign-ico:hover {
    color: white
}

.njs-close-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px
}
.njs-close-ico::after,
.njs-close-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 1px
}
.njs-close-ico::after {
    transform: rotate(-45deg)
}

.njs-close-o-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,0.9));
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-radius: 40px;
}
.njs-close-o-ico::after,
.njs-close-o-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 3px
}
.njs-close-o-ico::after {
    transform: rotate(-45deg)
}
.njs-close-o-ico:hover {
    color: white
}

.njs-arrow-up-ico {
box-sizing: border-box;
position: relative;
display: block;
transform: scale(var(--ggs,1));
width: 22px;
height: 22px
}
.njs-arrow-up-ico::after,
.njs-arrow-up-ico::before {
content: "";
display: block;
box-sizing: border-box;
position: absolute;
top: 4px
}
.njs-arrow-up-ico::after {
width: 8px;
height: 8px;
border-top: 2px solid;
border-left: 2px solid;
transform: rotate(45deg);
left: 7px
}
.njs-arrow-up-ico::before {
width: 2px;
height: 16px;
left: 10px;
background: currentColor
} 
.njs-arrow-up-ico:hover {
    color: white
}

.njs-square-ico {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 22px;
	height: 22px;
	border: 2px solid;
	transform: scale(var(--ggs,1));
	border-radius: 4px
 }

 .njs-rectangle-ico {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 32px;
	height: 12px;
	border: 2px solid;
	transform: scale(var(--ggs,1));
	border-radius: 4px
 }
.njs-rectangle-ico.top {
	top:10px;
}

.njs-square2-ico {
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	border: 1px solid;
}
.njs-square2-ico:hover {
    color: white
}

.njs-reset-ico{
	display: inline-block;
	vertical-align: middle;
	position: relative;
	font-style: normal;
	color: currentColor;
	text-align: left;
	text-indent: -9999px;
	direction: ltr;	

	width: 26px;
    height: 26px;
    border-width: 2px;
    border-style: solid;
	border-radius: 50%;
    margin: 4px;
    border-left-color: transparent;
}
.njs-reset-ico::before {
	content: '';
	pointer-events: none;	

    position: absolute;
    width: 0;
    height: 0;
    left: -6px;
    bottom: 0;
    border-width: 5px;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
	-webkit-transform: rotate(135deg);    
	transform: rotate(135deg);
}
.njs-reset-ico::after  {
	content: '';
	pointer-events: none;	
}

/* .njs-minus {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 16px;
    height: 2px;
    background: currentColor;
    border-radius: 10px
} */

.njs-remove-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-radius: 4px
}
.njs-remove-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: currentColor;
    border-radius: 5px;
    top: 8px;
    left: 4px
}
.njs-remove-ico:hover {
    color: white
}

.njs-divider-vertical-ico, .njs-divider-horizontal-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    border: 1px solid;
    border-radius: 3px;
    width: 20px;
    height: 20px
    }

.njs-divider-vertical-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 20px;
    top: -1px;
    left: 8px;
    background: currentColor
} 

.njs-divider-horizontal-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 18px;
    height: 2px;
    top: 8px;
    left: 0px;
    background: currentColor
} 


.njs-separator-horizontal-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    height: 16px;
    width: 18px;
    border-top: 1px solid;
    border-bottom: 1px solid;
}
.njs-separator-horizontal-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    height: 4px;
    width: 18px;
    background: currentColor;
    top: 5px;
}
.njs-separator-horizontal-ico:hover {
    color: white
}

.njs-separator-vertical-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    height: 18px;
    width: 18px;
    border-left: 1px solid;
    border-right: 1px solid
}
.njs-separator-vertical-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    height: 18px;
    width: 4px;
    background: currentColor;
    right: 6px;
}
.njs-separator-vertical-ico:hover {
    color: white
}

.njs-filechooser-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 14px;
    height: 16px;
    border: 2px solid transparent;
    border-right: 0;
    border-top: 0;
    box-shadow: 0 0 0 2px;
    border-radius: 1px;
    border-top-right-radius: 4px;
    overflow: hidden;
    top:3px
}
.njs-filechooser-ico::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 6px;
    height: 6px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    right: -1px;
    top: -1px
} 


.njs-radiobutton-ico,
.njs-radiobutton-ico::after {
 display: block;
 box-sizing: border-box;
 border-radius: 100px
}
.njs-radiobutton-ico {
 border: 2px solid;
 position: relative;
 transform: scale(var(--ggs,1));
 width: 20px;
 height: 20px
}
.njs-radiobutton-ico::after {
 content: "";
 position: absolute;
 width: 8px;
 height: 8px;
 background: currentColor;
 top: 4px;
 left: 4px
} 


.njs-calendar-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-radius: 3px
}   
.njs-calendar-ico::after,
.njs-calendar-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    height: 2px;
    left: 2px
}   
.njs-calendar-ico::before {
    background: currentColor;
    width: 2px;
    box-shadow:
    4px 0 0,8px 0 0,0 4px 0,
    4px 4px 0,8px 4px 0;
    top: 6px
}   
.njs-calendar-ico::after {
    width: 10px;
    top: -4px;
    box-shadow: 0 6px 0 0
} 

.njs-colorbucket-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 6px;
    height: 10px;
    border: 2px solid;
    border-bottom: 0;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    margin-top: 0px
}  
.njs-colorbucket-ico::after,
.njs-colorbucket-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    transform: rotate(45deg)
}  
.njs-colorbucket-ico::before {
    border: 2px solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    top: 4px;
    left: -4px;
    width: 14px;
    height: 16px
}  
.njs-colorbucket-ico::after {
    width: 6px;
    height: 6px;
    border-radius: 0 100% 100% 100%;
    background: currentColor;
    right: -15px;
    bottom: -12px
} 


.njs-colorpicker-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: rotate(-45deg) scale(var(--ggs,1));
    width: 18px;
    height: 10px;
    border-left: 0;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    box-shadow: inset 0 0 0 2px
}  
.njs-colorpicker-ico::after,
.njs-colorpicker-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    background: currentColor
}  
.njs-colorpicker-ico::before {
    top: -2px;
    border-radius: 3px;
    width: 2px;
    height: 10px;
    right: -3px
}  
.njs-colorpicker-ico::after {
    width: 8px;
    height: 6px;
    right: -9px;
    box-shadow: -21px 0 0 -2px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px
} 

.njs-mail-ico,
.njs-mail-ico::after {
 display: block;
 box-sizing: border-box;
 height: 14px;
 border: 2px solid
}

.njs-mail-ico {
 overflow: hidden;
 transform: scale(var(--ggs,1));
 position: relative;
 width: 18px;
 border-radius: 2px
}

.njs-mail-ico::after {
 content: "";
 position: absolute;
 border-radius: 3px;
 width: 14px;
 transform: rotate(-45deg);
 bottom: 3px;
 left: 0
} 


.njs-tabs-ico,
.njs-tabs-ico::before {
 display: block;
 box-sizing: border-box
}

.njs-tabs-ico {
 position: relative;
 transform: scale(var(--ggs,1));
 width: 20px;
 height: 16px;
 border: 2px solid;
 border-radius: 3px
}

.njs-tabs-ico::before {
 content: "";
 position: absolute;
 width: 10px;
 height: 6px;
 background: currentColor;
 border-radius: 1px;
 transform: skewX(20deg);
 top: -2px;
 right: -1px
} 

.njs-menuround-ico {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border-radius: 24px;
    border: 2px solid;
}
.njs-menuround-ico::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 2px;
    background: currentColor;
    border-radius: 3px;
    top: 4px;
    left: 4px;
    box-shadow: 0 4px 0, 0 8px 0
}
 
   

.njs-slider-ico {
    transform: scale(var(--ggs,1))
} 
.njs-slider-ico,
.njs-slider-ico::after,
.njs-slider-ico::before {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 22px;
    height: 3px;
    top:10px;
    background: currentColor;
    border-radius: 20px
}   
.njs-slider-ico::after,
.njs-slider-ico::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: -2px;
    left: 7px
} 


/* ICONS */



/* FONT CHOOSER */

.font-select {
    position: relative;
}

.font-select span {
	background-color: rgb(221, 221, 221);
  display: inline-block;
  padding: 4px 20px;
  font-weight: 700;
  font-family: Corben;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease-in 0s;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
  border-radius: 2px;
  margin-top: 5px;
}
	
.fs-drop {

    max-height: 50vh;
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    left: 0;
    top: 32px;
    border-radius: 4px;

}

.fs-results {

    padding: 0;

}

.fs-results li {

    list-style: none;
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    transition:0.2s ease-in;
}
.fs-results li:hover {
  background-color:#ddd;
}

/* FONT CHOOSER */

/* PATTERNS https://www.magicpattern.design/tools/css-backgrounds */

.wavy {
    /* background-color: #e5e5f7; */
    /* opacity: 0.4; */
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #f5f5f5 14px ), repeating-linear-gradient( #fbfbff55, #fbfbff );    
}
.rhombus {
    /* background-color: #e5e5f7; */
    /* opacity: 0.4; */
    background-image:  linear-gradient(135deg, #fbfbff 25%, transparent 25%), linear-gradient(225deg, #fbfbff 25%, transparent 25%), linear-gradient(45deg, #fbfbff 25%, transparent 25%), linear-gradient(315deg, #fbfbff 25%, #f5f5f5 25%);
    background-position:  14px 0, 14px 0, 0 0, 0 0;
    background-size: 14px 14px;
    background-repeat: repeat;
}
.zigzag {
    background-image:  linear-gradient(135deg, #fbfbff 25%, transparent 25%), linear-gradient(225deg, #fbfbff 25%, transparent 25%), linear-gradient(45deg, #fbfbff 25%, transparent 25%), linear-gradient(315deg, #fbfbff 25%, #f5f5f5 25%);
    background-position:  14px 0, 14px 0, 0 0, 0 0;
    background-size: 28px 28px;
    background-repeat: repeat;
}

/* PATTERNS */